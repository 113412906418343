<template>
  <div>
    <match-progress></match-progress>
    <section>
      <b-card>
        <b-row>
          <b-col md="12">
            <div class="d-flex align-items-end justify-content-end">
              <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                <template #button-content>
                  <b-button variant="outline-primary" class="ml-2" :disabled="!rows.length">
                    <feather-icon size="16" icon="DownloadIcon" class="mr-50" />
                    <span class="text-nowrap">Export</span>
                  </b-button>
                </template>

                <b-dropdown-item @click="onDownloadMatch('csv')">
                  <span class="align-middle ml-50">Export CSV</span>
                </b-dropdown-item>

                <b-dropdown-item @click="onDownloadMatch('xlsx')">
                  <span class="align-middle ml-50">Export XLSX</span>
                </b-dropdown-item>
              </b-dropdown>
              <action-button variant="primary" class="ml-2" @click="openMatchPoolSideBar">
                <feather-icon size="16" icon="PlusIcon" class="mr-50" />
                <span class="text-nowrap">New matching pools</span>
              </action-button>
            </div>
          </b-col>
        </b-row>
        <vue-good-table  :class="!isLoading && rows.length > 0 ?  'mt-3' : 'mt-3 hide-table'"
         mode="remote" :is-loading="isLoading" :columns="columns" :rows="rows" :rtl="isRtl"
      
          :search-options="{
            enabled: false,
          }" :pagination-options="{
          enabled: true,
          perPage: perPage,
        }" style-class="vgt-table striped" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'action'" cols="12" md="2"
              class="justify-content-between flex-wrap vgt-center-align">
              <div class="align-items-center mb-0 mt-0">
                
                  <action-button variant="outline-none" class="btn-icon"   @click="openExistingMatchPoolSideBar(props.row.id)">
                    <feather-icon icon="EditIcon" size="20" />
                  </action-button>
  
                <action-button variant="outline-none" class="btn-icon" @click="deletePool(props.row.id)">
                  <feather-icon icon="TrashIcon" size="20" />
                </action-button>
              </div>
            </div>
            <div v-if="props.column.field === 'pending_matches_count'" cols="12" md="2"
              class="justify-content-between flex-wrap vgt-center-align">
              <div class="align-items-center mb-0 mt-0">
              {{  Number(props.row.pending_matches_count) }}
              </div>
            </div>
            <div v-else-if="props.column.field === 'title'" cols="12" md="2" class="justify-content-between flex-wrap">
              <div class="align-items-center mb-0 mt-0">
                <router-link class="table-primary-link"
                  :to="{ name: 'champion-matching-pool-details', params: { id: defaultProgramId, poolId: props.row.id } }">
                  {{ props.row.title }}
                </router-link>
              </div>
            </div>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <table-pagination :per-page="perPage" :total="total" @perPageChanged="(value) => props.perPageChanged({ currentPerPage: value })
              " @pageChanged="(value) => props.pageChanged({ currentPage: value })
              " />
          </template>
        </vue-good-table>
        <match-alert   v-if="!isLoading && rows.length === 0"
        variant="success"
        show
        title="Create a pool to start matching participants"
        message="A matching pool pairs participants for locking or publishing"
        >
        </match-alert>

      </b-card>
      <div class="d-flex justify-content-end
    ">

    <router-link
        :to="{ name: 'champion-matches-locked-matches'}"
        class="btn btn-primary btn-icon mb-4"
    >
    Next
    <feather-icon
      icon="ArrowRightIcon"
    />
  </router-link>
            </div>
      <create-match-pool :open="openCreateMatchPool" title="Pool Properties" @close="closeSideBar"
        @create="loadItems" />
      <edit-match-pool
        :open="openExistingMatchPool"
        :pool="pool"
        @close="closeSideBar"
        @update="update"
      />  
        
      <b-modal id="updating-modal" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc>
        <div class="d-block text-center">
          <b-spinner medium variant="primary" class="mt-2" />
          <h5 class="mt-2">
            Updating...
          </h5>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import ActionButton from "@/views/components/common/ActionButton.vue";
import {
  BButton,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BModal,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BAlert
} from "bootstrap-vue";
import MatchProgress from "@/views/apps/champion/matches/MatchProgress.vue";
import MatchAlert from "@/views/apps/champion/matches/MatchAlert.vue";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import matchesService from "@/services/matchesService";
import { mapGetters } from 'vuex';
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import CreateMatchPool from "./CreateMatchPool.vue";
import { BE_API_URL } from "@/constants/app";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import EditMatchPool from "./EditMatchPool.vue";

export default {
  name: 'MatchingPools',
  components: {
    ActionButton,
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BModal,
    EditMatchPool,
    VueGoodTable,
    TablePagination,
    CreateMatchPool,
    BDropdown,
    BDropdownItem,
    MatchProgress,
    BAlert,
    MatchAlert
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      openEditMatchPool: false,
      pool: {},
      columns: [

        {
          label: "Title",
          field: 'title',
          filterOptions: {
            enabled: true,
            placeholder: "Title",
          },
          width: "10em",
        },
        {
          label: "Participants",
          field: 'pool_users_count',
          filterOptions: {
            enabled: true,
            placeholder: "Number of Participants"
          },
          width: "10em",
        },
        {
          label: "Paired",
          field: "pending_matches_count",
          filterOptions: {
            enabled: true,
            placeholder: "Number Locked",
          },
          width: "8em"
        },
        {
          label: "Unpaired",
          field: "unmatched_matches_count",
          filterOptions: {
            enabled: true,
            placeholder: "Number Locked",
          },
          width: "8em"
        },
        {
          label: "Locked",
          field: "locked_matches_count",
          filterOptions: {
            enabled: true,
            placeholder: "Number Locked",
          },
          width: "8em"
        },
        {
          field: "action",
          width: "8em",
          sortable: false
        }
      ],
      rows: [],
      columnFilters: [],
      sort: [],
      openCreateMatchPool: false,
      openExistingMatchPool: false,
      poolDetails: undefined
    };
  },
  computed: {
    ...mapGetters('programs', ['defaultProgramId']),
    isRtl() {
      return store.state.appConfig.isRTL;
    }
  },
  created() {
    this.loadItems();
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
    update() {
      this.selectAll = false;
      this.loadItems();
    },
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "title",
        "pool_users_count",
        "locked_matches_count"
      ]) {
        if (params.columnFilters[col]) {
          columnFilters.push({
            field: col,
            value: params.columnFilters[col],
          });
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await matchesService.getMatchPools(this.defaultProgramId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { items, total } = response.data;
        this.total = total;
        this.rows = items;
        
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
    },
    deletePool(poolId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this pool? Deleting a pool will not impact any published matches, however any locked matches will be lost.?', {
          title: 'Delete Pool ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.$bvModal.show('updating-modal');
              await matchesService.deletePool(this.defaultProgramId, poolId);
              this.$toast(makeSuccessToast('Pool deleted successfully.'));
              this.loadItems();
            } catch (e) {
              this.$toast(makeErrorToast('Something went wrong. Pool not deleted.'));
              this.$log.error(e);
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });
    },
    openMatchPoolSideBar() {
      this.openCreateMatchPool = true;
    },
    openExistingMatchPoolSideBar(id) {
      this.loadExistingPoolDetails(id)
    },
    async loadExistingPoolDetails(poolId) {
      try {
    
        const response = await matchesService.getMatchPool(this.defaultProgramId, poolId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        this.pool = response.data;
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
  
        this.openExistingMatchPool = true;
      } 
    },
    closeSideBar() {
      this.openExistingMatchPool = false;
      this.openCreateMatchPool = false;
    },
    onDownloadMatch(type) {
      let poolsEncode = this.rows.map(pool =>
        encodeURIComponent(JSON.stringify({ id: pool.id }))
      );
      const dowloadLink =
        `${BE_API_URL}/programs/${this.$route.params.id}/pools/matches/download/${type}` +
        `?search=${encodeURIComponent(
          JSON.stringify({
            page: this.page,
            columnFilters: this.columnFilters,
            sort: this.sort,
          })
        )}` +
        `&pools=[${poolsEncode.join(",")}]`;
      window.document.location = dowloadLink;
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page
    };
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-good-table.scss';
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
</style>
<style lang="scss" scoped>
.action-menu {
  display: inline-block;
}
.hide-table{
  display:none;
}
</style>